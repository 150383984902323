import React from 'react';
import './Header.css'; // Import the CSS file for styling
import logo from '../assets/images/logo.webp'; // Import the logo

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <a href="/">
          <img src={logo} alt="InCharacter.Me™ Logo" />
        </a>
        <div className="spacer"></div>
        <span className="brand">
          <a href="/">
            <span className="in">In</span>
            <span className="character">Character</span>
            <span className="dot">.</span>
            <span className="me">Me</span>
            <span className="tm">™</span>
          </a>
        </span>
      </div>
      <nav className="nav">
        <a href="/login">Login</a>
      </nav>
    </header>
  );
};

export default Header;
