import React from 'react';
import './Footer.css';
import logo from '../assets/images/asr.webp';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>
          &copy;
          <a
            href="https://www.astagereborn.com/"
            rel="noreferrer"
            target="_blank"
          >
            A Stage Reborn™
          </a>{' '}
          All Rights Reserved.
        </p>
        <p>
          InCharacter.Me™ and the InCharacter.Me™ logo are trademarks of
          <a
            href="https://www.astagereborn.com/"
            rel="noreferrer"
            target="_blank"
          >
            A Stage Reborn™
          </a>
        </p>
        <p>
          <a
            href="https://www.astagereborn.com/"
            rel="noreferrer"
            target="_blank"
          >
            A Stage Reborn™
          </a>{' '}
          is a registered and recognized 501(c)(3) Public Benefit nonprofit
          charity.
        </p>
        <p>
          <a
            href="https://www.paypal.com/fundraiser/charity/2456458"
            rel="noreferrer"
            target="_blank"
          >
            <img src={logo} alt="A Stage Reborn™ Logo" />
            Donate
          </a>{' '}
          | <a href="/terms">Terms</a> | <a href="/privacy">Privacy</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
