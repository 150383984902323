import React, { useEffect, useState } from 'react';
import './Home.css'; // Import the CSS file for styling

const images = [
  require('../assets/images/bg_1.webp'),
  require('../assets/images/bg_2.webp'),
  require('../assets/images/bg_3.webp'),
];

const Home = () => {
  const [currentImage, setCurrentImage] = useState('');

  useEffect(() => {
    if (images.length > 0) {
      const randomImage = images[Math.floor(Math.random() * images.length)];
      setCurrentImage(randomImage);
    } else {
      setCurrentImage(require('../assets/images/bg_1.webp'));
    }
  }, []);

  return (
    <div className="home">
      <div
        className="parallax"
        style={{ backgroundImage: `url(${currentImage})` }}
      ></div>
      <div className="content">Relaunch in progress, see you soon!</div>
    </div>
  );
};

export default Home;
