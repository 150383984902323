// metadata.js
import logo from './assets/images/logo.webp';

export const siteMetadata = {
  title: 'InCharacter.Me™',
  fullDescription:
    'InCharacter.Me™ is your gateway to revolutionary character story creation and management with embeddable creator content features enabling you to share art, engage with animations, and express your unique ideas. Join and explore a vibrant global network dedicated to creative expression powered by A Stage Reborn™.',
  shortDescription:
    'InCharacter.Me™ is your gateway to revolutionary character story creation and management powered by A Stage Reborn™.',
  url: 'https://incharacter.me',
  image: logo,
};
