import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { siteMetadata } from '../metadata';

const MetaTags = ({ description }) => (
  <HelmetProvider>
    <Helmet>
      <title>{siteMetadata.title}</title>
      <meta
        name="description"
        content={description || siteMetadata.description}
      />
      <meta property="og:title" content={siteMetadata.title} />
      <meta
        property="og:description"
        content={description || siteMetadata.description}
      />
      <meta property="og:image" content={siteMetadata.image} />
      <meta property="og:url" content={siteMetadata.url} />
      <meta property="og:type" content="website" />
    </Helmet>
  </HelmetProvider>
);

export default MetaTags;
